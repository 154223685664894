<template>
    <div class="c-widget--post-carousel c-tabs">
        <div class="c-tabs__controls">
            <button class="c-tabs__tab" :class="{ 'c-tabs__tab--active' : i === curr }" v-for="(tab, i) in tabs" :key="i" v-touch:tap="switchTab(i)">
                {{ tab.title }}
            </button>
        </div>
        <post-grid :posts="tabs[curr].posts">
            <template v-slot:default="slotProps">
                <p class="c-tease__meta" aria-label="Anzahl der Aufrufe des Artikels">
                    <span class="u-color--light-blue c-txt--bold">{{ curr === 0 ? slotProps.post.post_views || 0 : slotProps.post.post_likes || 0 }}</span><span>&emsp;{{ curr === 0 ?  singleViewsWord(slotProps.post.post_views) : singleLikesWord(slotProps.post.post_likes) }}</span>
                </p>
            </template>
        </post-grid>
    </div>
</template>

<script>
    import PostGrid from '@/components/partials/PostGrid'

    export default {
        name: 'ViewedLiked',
        props: {
            data: Object
        },

        data() {
            return {
                tabs: [
                    {
                        title: 'Meistgelesen',
                        posts: this.data['viewed_articles'].views.posts || []
                    },
                    {
                        title: 'Beliebt',
                        posts: this.data['viewed_articles'].likes.posts || []
                    }
                ],
                curr: 0,
            }
        },

        methods: {
          switchTab(index) {
              return () => {
                  this.curr = index
              }
          },
            singleViewsWord(count) {
                return count !== '1' ? 'Aufrufe' : 'Aufruf'
            },
            singleLikesWord(count) {
                return count !== '1' ? 'Likes' : 'Like'
            }
        },

        components: {
            PostGrid
        }
    }
</script>

<style scoped lang="scss" src="@/styles/components/_tabs.scss"></style>